import TextInput from "../Form/TextInput"
import TextArea from "../Form/TextArea"
import { useEffect, useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import CreateStore from "../../stores/CreateStore"
import { submitSuccess, submitError, successToast, errorToast, capitalizeFirstLetter } from '../Helpers'
import callApi from "../../utils/ApiCall"
import { useNavigate } from "react-router-dom"

export default function ImportRouteForm({ organisation, type }) {
  const [ routePoints, setRoutePoints ] = useState([])
  const [ totalDistance, setTotalDistance ] = useState(0)
  const [ description, setDescription ] = useState('')
  const [ drivingType, setDrivingType ] = useState('driving')
  const [ formErrorFields, setFormErrorFields ] = useState([])
  const [ savingRoute, setSavingRoute ] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleDescription = useCallback((newValue) => {
    setDescription(newValue)
    if (newValue.length >= 20 && formErrorFields.length > 0) {
      const descriptionError = formErrorFields.find(error => error.field === 'description')
      if (!descriptionError) return
      
      setFormErrorFields([])
    }
  }, [formErrorFields, setFormErrorFields])
  
  const handleRoutePoints = useCallback((newValue) => {
    console.log('handleRoutePoints: ', newValue);

    if (newValue !== '') {
      console.log('handleRoutePoints: ', JSON.parse(newValue));
      // CreateStore.setRoutePoints(JSON.parse(newValue))
      const points = JSON.parse(newValue)
      points.forEach(p => {
        CreateStore.addRoutePoint(p)
      });
    } else {
      // CreateStore.setRoutePoints(newValue)
      routePoints.forEach(p => {
        CreateStore.removeRoutePoint(p)
      });
    }

    setRoutePoints(newValue)
  }, [CreateStore, setRoutePoints])

  // const checkDistanceAllowed = useCallback(async(distance) => {
  //   let url
  //   if (authType === 'Manager') {
  //     url = `/api/${organisation.uuid}/routes/create/allowed/${distance}`
  //   } else {
  //     url = `/api/routes/create/allowed/${distance}`
  //   }

  //   if (type === 'Shared') {
  //     url += '/shared'
  //   }
    
  //   await callApi(url)
  //     .then(function (response) {
  //       // console.log(response)
  //       if (!response.data.distance_allowed) {
  //         const distanceAllowedError = {
  //           field: 'distance',
  //           message: t('distance error')
  //           // 'Not enough credits left to create the route. Please shorting your route.'
  //         }
  //         setFormErrorFields([...formErrorFields, distanceAllowedError])
  //       } else {
  //         removeDistanceError()
  //       }
  //     })
  //     .catch(function (error) {
  //       console.error(error)
  //     });
  // }, [type, authType, organisation, t, formErrorFields, setFormErrorFields, removeDistanceError])

  const handleDistance = useCallback(() => {
    const distance = CreateStore.getRouteDistance()
    setTotalDistance(distance)
    // if (type !== 'Free') {
    //   checkDistanceAllowed(distance)
    // }
  // }, [type, checkDistanceAllowed, setTotalDistance])
}, [type, setTotalDistance])

  const errorMessage = useCallback((fieldName)  => {
    // console.log('formErrorFields: ', formErrorFields);
    if (formErrorFields.length === 0) return null
    // console.log(formErrorFields.find(error => error.field === fieldName));

    return formErrorFields.find(error => error.field === fieldName)
  }, [formErrorFields])

  const routeData = useCallback(() => {
    const directionResult = CreateStore.getDirectionsResult()

    const routes = directionResult.routes[0]
    const legs = routes.legs
    const steps = legs.map((leg) => {
      return leg.steps.map(step => {
        return step.lat_lngs.map(lat_lngs => [lat_lngs.lat(), lat_lngs.lng()])
      })
    })

    const request = directionResult.request
    const origin_point = request.origin.location.toJSON()
    const destination_point = request.destination.location.toJSON()

    // console.log('routePoints: ', routePoints);

    return {
      community: type,
      description,
      routepoints: routePoints,
      start_point: [origin_point.lat, origin_point.lng],
      end_point: [destination_point.lat, destination_point.lng],
      waypoints: request['waypoints'],
      distance: totalDistance,
      travelmode: drivingType,
      bounds: routes['bounds'],
      // overview_polyline: this.directionResult['overview_polyline'],
      overview_polyline: directionResult.overview_polyline,
      overview_path: routes['overview_path'],
      summary: routes['summary'],
      warnings: routes['warnings'],
      waypoint_order: routes['waypoint_order'],
      steps: steps.flat(2),
      country_code: CreateStore.getCountryCode(),
      directions: directionResult
    }
  }, [description, totalDistance, type, routePoints, drivingType])

  const checkRouteStatus = useCallback(async (routeId) => {
    let url
    // if (authType === 'Manager' && type !== 'Free') {
      // url = `/api/${organisation.uuid}/routes/${routeId}/status`
    // } else if (authType === 'Manager') {
      url = `/api/routes/free/${routeId}/status`
    // } else {
      // url = `/api/routes/${routeId}/status`
    // }
    return await callApi(url)
      .then(function (response) {
        // console.log(response)
        return response.data
      })
      .catch(async function (error) {
        const errorResponse = await error.json()
        console.error(errorResponse)
      });
  }, [type, organisation])

  const checkRouteInterval = useCallback((routeId) => {
    let looper = setInterval(async () => {
      let output = await checkRouteStatus(routeId)
      // console.log(output);
      if (output.id) {
        clearInterval(looper);
        CreateStore.clearRoutePoints()
        successToast(
          t('first run')
        )
        navigate(`/routes/${output.id}/create`)
      }
    }, 1500);
  }, [checkRouteStatus, t, navigate])

  const saveRoute = useCallback((e) => {
    e.preventDefault()
    
    if (formErrorFields.length > 0 || routePoints.length < 2) return
    
    const data = routeData()

    console.log('data: ', data)
    if (data === undefined) return

    // routeData['description'] = description
    data['community'] = 'Free'

    let url
    // if (authType === 'Manager' && type !== 'Free') {
      // url = `/api/${organisation.uuid}/routes/create`
    // } else if (authType === 'Manager') {
      url = `/api/routes/free/create`
    // } else {
      // url = `/api/routes/create`
    // }

    // CreateStore.setSavingRoute(true)
    setSavingRoute(true)

    callApi(
      url,
      'POST',
      data
    )
    .then(function (response) {
      // console.log(response)
      if (submitSuccess(response)) {
        successToast(
          t('route map created')
        )

        checkRouteInterval(response.data.route.id)
      }
    })
    .catch(async function (error) {
      const response = await error.json()
      if (submitError(error)) {
        console.log(response);
        errorToast(t(response.message))

        setFormErrorFields(response.fields)
        setSavingRoute(false)
      }
    })
  }, [formErrorFields, routePoints, routeData, t, organisation, type, setFormErrorFields, setSavingRoute, checkRouteInterval])

  useEffect(() => {
    CreateStore.addRouteDistanceChangeListener(handleDistance)
    return () => {
      CreateStore.removeRouteDistanceChangeListener(handleDistance)
    }
  }, [])

  return (
      <form
        onSubmit={
          (e) => saveRoute(e)
        }
      >
        <small>
          <b>{capitalizeFirstLetter(t('distance'))}:</b> {totalDistance}m
        </small>

        <TextInput
          title={capitalizeFirstLetter(t('description'))}
          helper={capitalizeFirstLetter(t('min 20 chars'))}
          name={'description'}
          value={description}
          handleChange={handleDescription}
          readonly={savingRoute}
          focus={true}
        />

        <TextArea
          title={capitalizeFirstLetter(t('routepoints'))}
          name="routepoints"
          value={routePoints}
          handleChange={(val) => handleRoutePoints(val)}
          readonly={savingRoute}
        />

        {(routePoints.length >= 2 && !errorMessage('distance') && !savingRoute) && (
          <input
            type="submit"
            className='btn btn-primary'
            value={capitalizeFirstLetter(t('create route')) }
          />
        )}

        {savingRoute && (
          <button className='btn btn-primary' disabled>
            <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <span className="ms-2" role="status">{capitalizeFirstLetter(t('saving'))}</span>
          </button>
        )}
      </form>
  )
}