import ImportRouteForm from "./ImportRouteForm"
import { useEffect, useState, useCallback } from "react"
import { capitalizeFirstLetter } from '../Helpers'
import { Wrapper, Status } from "@googlemaps/react-wrapper"
import ImportMap from './ImportMap'
import { useTranslation } from "react-i18next"
import CheckBox from '../Form/CheckBox'
import Loader from '../Views/Loader'
import CreateStore from "../../stores/CreateStore"

export default function ImportRoute({ organisation })
{
  const { t } = useTranslation()

  const address = `${organisation.street1}, ${organisation.postalcode} ${organisation.city}, ${organisation.country_name}`
  const center = ''
  const divStyle = {
    width: 'auto',
    height: '100%'
  }

  // const handleChange = useCallback((fieldValue, fieldName) => {
  //   const updatedFormData = { ...formData }
  //   updatedFormData[fieldName] = fieldValue
  //   setFormData(updatedFormData)
  // }, [])

  const mapRender = (status) => {
    return (
      <div className='h-100 d-flex align-items-center'>
        {status === Status.FAILURE ? (
          // 'Error loading the map.'
          t('map error')
        ) : (
          <Loader />
        )}
      </div>
    )
  }
  
  // useEffect(() => {
  //   console.log(organisation)
  // }, [organisation])

  return (
    <>
      <h1>Import</h1>
      
      <Wrapper apiKey={process.env.REACT_APP_MAPS_API_KEY} render={mapRender}>
        <div className='row h-100'>
          <div className='col'>
            <ImportRouteForm
              organisation={organisation}
              type='Free'
            />
          </div>

          <div className='col-9 border p-0 position-relative'>
            {/* <small className='position-absolute z-3 bg-white px-2' style={{ 'right': 0 }}>
              <CheckBox
                onChange={(checked) => this.toggleStreetViewLayer(checked)}
                label='Streetview layer'
                defaultValue={true}
              />
            </small> */}
            <ImportMap
              divStyle={divStyle}
              address={address}
              center={center}
            />
          </div>
        </div>
      </Wrapper>

    </>
  )
}