import PermissionError from './Views/PermissionError'
import Loader from './Views/Loader'

import ManagerPages from './ManagerPages'
import AdminPages from './AdminPages'

import callApi from '../utils/ApiCall';

import { useState, useEffect, useCallback } from "react";
import { useMsal } from "@azure/msal-react";

import i18n from "i18next";
import Backend from "i18next-http-backend";
import { useTranslation, initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    // resources: {
    //   en: {
    //     translation: {
    //       "Welcome to React": "Welcome to React and react-i18next"
    //     }
    //   }
    // },
    debug: process.env.REACT_APP_ENV === 'dev' ? true : false,
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default function Pages() {
  const { instance } = useMsal();
  const [ organisation, setOrganisation ] = useState(null)
  const [ user, setUser ] = useState(null)
  const [ checkingPermissions, setCheckingPermissions] = useState(true)
  const [ requestError, setRequestError] = useState(null)
  const [ isManager, setIsManager] = useState(false)
  const [ isAdmin, setIsAdmin] = useState(false)

  const [ containerClasses, setContainerClasses ] = useState('container py-4 d-flex flex-column h-100')
  const [ navPage, setNavPage ] = useState('Home')
  const [ headerClasses, setHeaderClasses ] = useState('p-3 border-bottom')

  const { t, i18n } = useTranslation();

  function setPageClasses(sub, page = null) {
    const defaultContainerClasses = 'container py-4 d-flex flex-column h-100'

    if (navPage !== sub) setNavPage(sub)
    
    if (page === 'RoutePlayer') {
      setContainerClasses('h-100')
    } else {
      const classes = page === 'RoutePlayer' && containerClasses === defaultContainerClasses ? 'h-100' : defaultContainerClasses
      setContainerClasses(classes)
    }

    // console.log('Page: ', page);
    if (page === 'Create') {
      hideHeader()
    } else {
      showHeader()
    }
  }

  
  function hideHeader() {
    setHeaderClasses('d-none')
  }

  function showHeader() {
    setHeaderClasses('p-3 border-bottom')
  }

  const checkAuth = useCallback(async() => {
    return await callApi('/api/auth/check')
      .then(function (response) {
        // console.log(response);
        return response.data
      })
      .catch(async function (error) {
        const errorResponse = await error.json()
        console.error(errorResponse);
        return { account: { isManager: false, isAdmin: false, isActive: false } }
      });
  }, [])

  const updateOrganisation = useCallback(async () => {
    return await callApi('/organisation')
      .then(function (response) {
        // console.log(response);
        const { organisation } = response.data
        setOrganisation(organisation)
      })
      .catch(async function (error) {
        const errorResponse = await error.json()
        console.error(errorResponse);
      });
  }, [])

  const checkAuthLevel = useCallback(async() => {
    const account = instance.getActiveAccount()
    if (!account) return

    const authData = await checkAuth()
    const { isManager, isAdmin, isActive } = authData.account

    i18n.changeLanguage(authData.account.language_code)

    if (isManager && isActive) {
      setIsManager(true)
    } else if (!isActive || !authData.organisation.active) {
      handleError({
        code: 403,
        message: 'account inactive'
      })
      return
    } else if (!isManager && !isAdmin) {
      handleError({ code: 403 })
      return
    } else if (isAdmin) {
      setIsAdmin(isAdmin)
      setOrganisation(authData.organisation)
    }

    setRequestError(null)
    setUser(authData.account)

    setCheckingPermissions(false)
  }, [instance, checkAuth, i18n])

  useEffect(() => {
    if (checkingPermissions) checkAuthLevel()
  }, [checkingPermissions, checkAuthLevel])


  // const handleLogin = () => {
  //   // if (loginType === "popup") {
  //   //     instance.loginPopup(loginRequest);
  //   // } else if (loginType === "redirect") {
  //       instance.loginRedirect(loginRequest);
  //   // }
  // }

  const recheckPermissions = () => {
    setCheckingPermissions(true)
  }

  const handleLogout = () => {
    // if (logoutType === "popup") {
      // instance.logoutPopup();
    // } else if (logoutType === "redirect") {
      // }
    const logoutConfirm = window.confirm(t('confirm'))
    if (logoutConfirm) {
      instance.logoutRedirect();
      localStorage.removeItem('organisation-uuid')
    }
  };

  const handleError = (error) => {
    // console.error('Error: ', error);

    setRequestError(error)
    setCheckingPermissions(false)
  }

  function nav_link_classes(link) {
    return navPage === link ? 'nav-link px-2 text-secondary' : 'nav-link px-2'
  }

  if (checkingPermissions) {
    const loaderContainerStyles = {
      height: '100vh',
      width: '100vw'
    }
    return (
      <div className='d-flex align-items-center' style={loaderContainerStyles}>
        <Loader />
      </div>
    )
  } else {
    if (requestError) {
      return (
        <>
          <PermissionError
            error={requestError}
            recheckPermissions={recheckPermissions}
            handleLogout={handleLogout}
          />
        </>
      )
    }

    if (isManager) {
      return (
        <ManagerPages
          user={user}
          navPage={navPage}
          nav_link_classes={nav_link_classes}
          handleLogout={handleLogout}
          containerClasses={containerClasses}
          setContainerClasses={setContainerClasses}
          headerClasses={headerClasses}
          setPageClasses={setPageClasses}
        />
      )
    }

    if (isAdmin) {
      return (
        <AdminPages
          user={user}
          nav_link_classes={nav_link_classes}
          handleLogout={handleLogout}
          containerClasses={containerClasses}
          headerClasses={headerClasses}
          setPageClasses={setPageClasses}
          organisation={organisation}
          updateOrganisation={updateOrganisation}
          setContainerClasses={setContainerClasses}
        />
      )
    }
  }
}