import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter, submitSuccess, successToast } from '../Helpers';
import callApi from '../../utils/ApiCall';
import { useState, useCallback } from 'react';
import Loader from '../Views/Loader';

export default function CycleRouteRow({ cycleRoute, type, authType, orgUuid, reFetchCycleRoutes }) {
  const runUrl = `/routes/${cycleRoute.id}/play`
  const { t } = useTranslation('translation');
  const [ isDeleting, setIsDeleting ] = useState(false)
  const [ isMarking, setIsMarking ] = useState(false)


  const hideRoute = useCallback(async(cycleRouteId) => {
    const confirmed = window.confirm(t('confirm'))
    if (!confirmed) return

    setIsDeleting(true)
    let url
    if (authType === 'Manager') {
      url = `/api/manager/routes/${cycleRouteId}`
    } else {
      url =`/api/routes/${cycleRouteId}`
    }

    return await callApi(
        url,
        'DELETE'
      ).then(function (response) {
        if (submitSuccess(response)) {
          successToast(
            'Route removed!'
          )
          reFetchCycleRoutes()
        }
      })
      .catch(async function (error) {
        const errorResponse = await error.json()
        console.error(errorResponse);
        // return { account: { isManager: false, isAdmin: false, isActive: false } }
      });
  }, [t, authType, reFetchCycleRoutes])

  const unMarkAsDemo = useCallback(async (cycleRouteId) => {
    if (authType !== 'Manager') return

    const confirmed = window.confirm(t('confirm'))
    if (!confirmed) return

    setIsMarking(true)

    return await callApi(
      `/api/manager/routes/${cycleRouteId}/unmark_as_demo`,
      'PATCH'
    ).then(function (response) {
      if (submitSuccess(response)) {
        successToast(
          'Route marked as regular!'
        )
        reFetchCycleRoutes()
      }
    })
    .catch(async function (error) {
      const errorResponse = await error.json()
      console.error(errorResponse);
      // return { account: { isManager: false, isAdmin: false, isActive: false } }
    });
  }, [t, authType, reFetchCycleRoutes])

  const markAsDemo = useCallback(async (cycleRouteId) => {
    if (authType !== 'Manager') return

    const confirmed = window.confirm(t('confirm'))
    if (!confirmed) return

    setIsMarking(true)

    return await callApi(
      `/api/manager/routes/${cycleRouteId}/mark_as_demo`,
      'PATCH'
    ).then(function (response) {
      if (submitSuccess(response)) {
        successToast(
          'Route marked as demo!'
        )
        reFetchCycleRoutes()
      }
    })
    .catch(async function (error) {
      const errorResponse = await error.json()
      console.error(errorResponse);
      // return { account: { isManager: false, isAdmin: false, isActive: false } }
    });
  }, [t, authType, reFetchCycleRoutes])

  const canManageRoute = useCallback(() => {    
    const typeName = type.toLowerCase().split(' ').join('/')
    if (authType === 'Manager') return true

    if (typeName === 'free') return false

    if (authType === 'Admin' && orgUuid === cycleRoute.org_uuid) return true
  }, [type, orgUuid, authType, cycleRoute])

  // const canMarkDemoRoutes = useCallback(() => {    
  //   // const typeName = type.toLowerCase().split(' ').join('/')
  //   if (authType === 'Manager') return true

  //   return false
  // }, [authType])
  
  const editUrl = `/routes/${cycleRoute.id}/edit`

  return (
    <tr>
      <td>
        {cycleRoute.description}
        <div className="d-flex w-100">
          {cycleRoute.distance !== null && (
            <small className=''>
              <b>{capitalizeFirstLetter(t('distance'))}:</b> {cycleRoute.internal_distance / 1000}km
            </small>
          )}
          {authType === 'Manager' && cycleRoute.show_type === 'DEMO' && (
            <div className="ms-auto badge text-bg-warning">
              DEMO
            </div>
          )}
        </div>
      </td>
      <td>
        {cycleRoute.last_run}
        <div className='d-flex'>
          <small className=''>
            <b>{capitalizeFirstLetter(t('popularity'))}:</b> {cycleRoute.counter}
          </small>

          {cycleRoute.pois > 0 && (
            <small className='ms-auto'>
              <b>Pois:</b> {cycleRoute.pois}
            </small>
          )}
        </div>
      </td>
      <td>
        {cycleRoute.finished && (
          <div className="d-flex w-100">
            <Link to={runUrl} className='ms-2 btn btn-primary btn-sm'>
              {capitalizeFirstLetter(t('run'))}
            </Link>
            {canManageRoute() && (
              <>
                <Link to={`/routes/${cycleRoute.id}/poi`} className={`ms-2 btn ${cycleRoute.pois > 0 ? 'btn-primary' : 'btn-secondary'} btn-sm`}>
                  POI
                </Link>
              
                <div className="d-flex ms-auto">
                  <Link to={editUrl} className='ms-2 btn btn-secondary btn-sm'>
                    Edit
                  </Link>
                  <button onClick={() => hideRoute(cycleRoute.id)} className='ms-2 btn btn-danger btn-sm'>
                    {isDeleting ? (
                      <div className='d-flex align-items-center'>
                        <Loader
                          textColor=''
                          style={{width: '1.275rem', height: '1.275rem', marginRight: '1rem' }}
                        />
                        Deleting...
                      </div>
                    ) : (
                      'Delete'
                    )}
                  </button>
                </div>
              </>
            )}

            {authType === 'Manager' && (
              <>
                <div>
                  {cycleRoute.show_type !== 'DEMO' && (
                    <button onClick={() => markAsDemo(cycleRoute.id)} className='ms-2 btn btn-warning btn-sm'>
                      {isMarking ? (
                        <div className='d-flex align-items-center'>
                          <Loader
                            textColor=''
                            style={{width: '1.275rem', height: '1.275rem', marginRight: '1rem' }}
                          />
                          Marking...
                        </div>
                      ) : (
                        'Demo'
                      )}
                    </button>
                  )}
                  
                  {cycleRoute.show_type === 'DEMO' && (
                    <button onClick={() => unMarkAsDemo(cycleRoute.id)} className='ms-2 btn btn-warning btn-sm'>
                      {isMarking ? (
                        <div className='d-flex align-items-center'>
                          <Loader
                            textColor=''
                            style={{width: '1.275rem', height: '1.275rem', marginRight: '1rem' }}
                          />
                          Marking...
                        </div>
                      ) : (
                        'Regular'
                      )}
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </td>
    </tr>
  )
}
