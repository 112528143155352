import { useRef, useEffect, useCallback, useMemo } from 'react'
import { defaultToast, errorToast } from '../Helpers'
import { useTranslation } from 'react-i18next';
import CreateStore from '../../stores/CreateStore';

// routePoints, drawRoute,
export default function ImportMap ({ divStyle, address, center = '' }) {
  const ref = useRef()
  const map = useRef()
  const streetViewLayer = useRef()
  const { t } = useTranslation('translation');
  const newCenter = useRef()
  // const countryCodes = useRef([])
  const firstMarker = useRef()
  const directions = useRef()
  const directionsDisplay = useRef()
  const drivingType = useRef('DRIVING')

  // let zoomLevel = useRef(zoom)
  let zoomLevel = useRef(16)

  const drawFirstMarker = useCallback((location) => {
    firstMarker.current = new window.google.maps.Marker({
      map: map.current,
      position: location,
      label: 'A'
    });
  }, [map])

  const displayDirections = useCallback(() => {
    const polylineOptions = {
      strokeColor: '#99ff00', //red:#FF0000 , greenish:#99ff00
      strokeOpacity: 0.7,
      strokeWeight: 10
    }

    directionsDisplay.current = new window.google.maps.DirectionsRenderer({
      polylineOptions: polylineOptions,
      suppressBicyclingLayer: false
    })

    directionsDisplay.current.setMap(map.current);
    directionsDisplay.current.set('directions', directions.current)
    CreateStore.setDirectionsResult(directions.current)
  }, [])

  const removeDirections = useCallback(() => {
    if (!directionsDisplay.current) return

    directionsDisplay.current.setMap(null)
    directionsDisplay.current.set('directions', null)
  }, [])

  const handleRouteDistance = useCallback(() => {
    let totalDistance = 0
    directions.current.routes[0].legs.forEach(leg => {
      totalDistance += leg.distance.value
    })

    CreateStore.setRouteDistance(totalDistance)
  }, [])

  const drawRoute = useCallback(async () => {
    const routePoints = CreateStore.getRoutePoints()
    console.log('drawRoute: ', routePoints);

    const waypoints = [];

    if (routePoints.length === 0) {
      if (firstMarker.current) firstMarker.current.setMap(null)
      console.log('returning...');
      return
    } else if (routePoints.length === 1) {
      const location = new window.google.maps.LatLng({lat: parseFloat(routePoints[0].lat), lng: parseFloat(routePoints[0].lng)})
      drawFirstMarker(location)
      removeDirections()
      return
    // } else if (routePoints.length === 1) {
    //   firstMarker.current.setMap(map.current)
    //   return
    } else if (firstMarker.current) {
      firstMarker.current.setMap(null)
      // firstMarker.current = null
    }

    // if (routePoints.length <= 1) return


    // Create the DirectionsRequest object
    // if (routePoints.length > 1) {
      // markers.forEach(marker => {
      //   marker.setMap(null)
      // })
      const geocoder = new window.google.maps.Geocoder()
      for (let i = 0; i < routePoints.length; i++) {
        const point = {
          lat: parseFloat(routePoints[i].lat),
          lng: parseFloat(routePoints[i].lng),
        }
        geocoder.geocode({'location': point}, (results) => {
          checkGeocoderStatus(results, point)
        })

        if (i !== 0 && i !== routePoints.length - 1 ) {
          waypoints.push({
            location: new window.google.maps.LatLng({lat: parseFloat(routePoints[i].lat), lng: parseFloat(routePoints[i].lng)})
          })
        }
      }

      const travelMode = drivingType.current

      let directionsRequest = {
        origin: new window.google.maps.LatLng({lat: parseFloat(routePoints[0].lat), lng: parseFloat(routePoints[0].lng)}),
        destination: new window.google.maps.LatLng({
          lat: parseFloat(routePoints[routePoints.length - 1].lat),
          lng: parseFloat(routePoints[routePoints.length - 1].lng)
        }),
        waypoints: waypoints,
        provideRouteAlternatives: false,
        // travelMode: window.google.maps.TravelMode.DRIVING, //DRIVING, BICYCLING, TRANSIT, WALKING
        // travelMode: window.google.maps.TravelMode.BICYCLING, //DRIVING, BICYCLING, TRANSIT, WALKING
        travelMode,
        unitSystem: window.google.maps.UnitSystem.METRIC, //METRIC, IMPERIAL
        avoidFerries: true,
        avoidHighways: true,
        avoidTolls: true,
      };
    
      // Request the route
      const directionsService = new window.google.maps.DirectionsService()
      await directionsService.route(directionsRequest, async (result, status) => {
        console.log('result: ', result)
        console.log('status: ', status);
        if (status === 'OK') {
          // return result
          if (directions.current !== result) {
            if (directions.current) removeDirections()
            directions.current = result
            displayDirections()
            handleRouteDistance()
          }
        } else {
          console.error('DirectionsService Error: ', status);
        }
      })
  }, [drawFirstMarker, displayDirections, handleRouteDistance, removeDirections])

  const handleDrivingType = useCallback(() => {
    const newDrivingType = CreateStore.getDrivingType()
    if (drivingType.current !== newDrivingType) {
      drivingType.current = newDrivingType.toUpperCase()
      drawRoute()
    }
  }, [drivingType, drawRoute])

  const checkGeocoderStatus = useCallback((results, latlng) => {
    console.log(results);
    if (results[1]) {
      const countryCode = results[1].address_components.find(component => component.types.includes('country')).short_name
      // countryCodes.current.push(countryCode)
      // console.log(countryCodes);
      // const latLng = latlng.toJSON()
      // const routePoint = {
      //   lat: latLng.lat,
      //   lng: latLng.lng,
      //   name: results[1].formatted_address,
      //   unknownlocation: false
      // }
      // updateState(zoomLevel, latLng, routePoint, countryCodes.current)
      // CreateStore.addRoutePoint(routePoint)
      CreateStore.addCountryCode(countryCode)
    }
  }, [])

  const setStreetViewLayer = useCallback(() => {
    const show = CreateStore.getShowLayer()

    if (show) {
      streetViewLayer.current.setMap(map.current)
    } else {
      streetViewLayer.current.setMap(null)
    }
  }, [streetViewLayer, map])

  const createMap = useCallback(() => {
    // console.log('createMap')
    map.current = new window.google.maps.Map(ref.current, {
      // center: setCenter(),
      center: newCenter.current,
      zoom: zoomLevel.current,
      streetViewControl: false,
      fullscreenControl: false
    })
    const customStyled = [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [
          { visibility: "off" }
        ]
      }
    ]
    map.current.set('styles',customStyled)
    
    // const geocoder = new window.google.maps.Geocoder()

    // let markers = []
    // window.google.maps.event.addListener(map.current, 'click', (event) => {
    //   const routePoints = CreateStore.getRoutePoints()
    //   // console.log('routePoints: ', routePoints.length);
    //   if (routePoints.length >= 10) {
    //     errorToast(
    //       t('max points error')
    //     )
    //     return
    //   }

      // Get the name of the location
      // geocoder.geocode({'location': event.latLng}, (results) => {
      //   checkGeocoderStatus(results, event.latLng)
      // })
    // })

    streetViewLayer.current = new window.google.maps.StreetViewCoverageLayer();
    setStreetViewLayer()
    // const streetViewLayer = new window.google.maps.StreetViewCoverageLayer();
    // streetViewLayer.setMap(map);

    window.google.maps.event.addListener(map.current, 'zoom_changed', () => {
      zoomLevel.current = map.current.getZoom()

      // if (zoomLevel <= 14) {
      //   streetViewLayer.setMap(null);
      // } else {
      //   streetViewLayer.setMap(map);
      // }
    })

    // const streetViewLayer = new window.google.maps.StreetViewCoverageLayer();
    // streetViewLayer.setMap(map);

    drawRoute()

    // if (routePoints.length === 1) {
    //   const location = new window.google.maps.LatLng({lat: routePoints[0].lat, lng: routePoints[0].lng})
    //   drawFirstMarker(location)
    // }
  }, [t, map, zoomLevel, checkGeocoderStatus, setStreetViewLayer, drawRoute])
  // routePoints, 

  const setCenter = useCallback(async () => {
    if (center !== '') {
      newCenter.current = center
      createMap()
      return
    }

    const geocoder = new window.google.maps.Geocoder();
    return await geocoder.geocode({address}, (results) => {
      // console.log('address geocode result:', results);
      // let newCenter
      if (results !== null && !results[0].partial_match) {
        newCenter.current = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() }
      } else {
        newCenter.current = { lat: 51.035262618643095, lng: 3.7415651997680577}
        defaultToast(
          t('invalid organisation address')
        )
      }
      // updateCenter(newCenter.current)
      // map.current.setCenter(newCenter.current)
      createMap()
    })
  }, [createMap, newCenter, address, center, t])
  
  useEffect(() => {
    console.log('map load... ', CreateStore.getRoutePoints());
    setCenter();

    CreateStore.addShowLayerChangeListener(setStreetViewLayer)
    CreateStore.addRoutePointsChangeListener(drawRoute)
    CreateStore.addDrivingTypeChangeListener(handleDrivingType)

    return () => {
      CreateStore.removeShowLayerChangeListener(setStreetViewLayer)
      CreateStore.removeRoutePointsChangeListener(drawRoute)
      CreateStore.removeDrivingTypeChangeListener(handleDrivingType)
    }
  })

  return (
    <div ref={ref} id="map" style={divStyle} />
  )
}
