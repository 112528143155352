import {
  Link,
  Routes,
  Route,
  useLocation
} from 'react-router-dom'
import { useState, useEffect, useCallback } from 'react'

import Dashboard from './Manager/Dashboard'
import Accounts from './Accounts/Accounts'
import ClosedAccounts from './Accounts/ClosedAccounts'
import NewAccount from './Accounts/NewAccount'
import EditAccount from './Accounts/EditAccount'
import NotFound from './Views/NotFound'
import NewCycleRoute from './CycleRoutes/NewCycleRoute'
import EditCycleRoute from './CycleRoutes/EditCycleRoute'
import ImportRoute from './CycleRoutes/ImportRoute'
// import RoutePlayer from './Player/RoutePlayer'
import RoutePlayer from './v2/Player/RoutePlayer'
import RoutePoiPlayer from './v2/POI/RoutePoiPlayer'
import Creator from './v2/Creator/Creator'
import CycleRoutes from './CycleRoutes/CycleRoutes'
import Organisations from './Manager/Organisations'
import { capitalizeFirstLetter, truncateString } from './Helpers'
import callApi from '../utils/ApiCall'
import Loader from './Views/Loader'
import { useTranslation } from 'react-i18next';

export default function ManagerPages({ user, nav_link_classes, handleLogout, containerClasses, setContainerClasses, headerClasses, setPageClasses }) {
  const [ loadingOrganisation, setIsLoadingOrganisation ] = useState(true)
  const [ organisation, setOrganisation ] = useState(null)
  const { t } = useTranslation('translation');
  const AUTH_TYPE = 'Manager'

  let location = useLocation();
  const pathIsPlayOrCreate = location.pathname.endsWith('play') || location.pathname.endsWith('poi') || location.pathname.endsWith('create')

  const getOrganisation = useCallback(async (orgUuid) => {
    await callApi(`/api/organisations/${orgUuid}`)
      .then(function (response) {
        setOrganisation(response.data)
      })
      .catch(async function (error) {
        const errorResponse = await error.json()
        console.error(errorResponse);
      });
    setIsLoadingOrganisation(false)
  }, [])

  useEffect(() => {
    console.log('Manager Pages...')
    const orgUuid = localStorage.getItem('organisation-uuid')

    if (orgUuid && organisation === null) {
      getOrganisation(orgUuid);
    } else {
      setIsLoadingOrganisation(false)
    }
  }, [location, organisation, setOrganisation, getOrganisation])

  return (
    <>
      <header className={headerClasses}>
        <div className="container">
          <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <Link to="/" className="d-flex align-items-center mb-2 mb-lg-0 text-decoration-none text-black">
              <img src='/static/img/logo_website.png' alt='Logo Memomove' />
              <h4 className='ms-2 mb-0'>
                MemoMove | Admin { t("portal") }
              </h4>
            </Link>

            <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 ms-4 justify-content-center mb-md-0">
              <li>
                <Link to="/" className={nav_link_classes('Home')}>Home</Link>
              </li>
              {organisation && (
                <>
                  <li>
                    <Link to="/accounts" className={nav_link_classes('Accounts')}>
                      Accounts
                    </Link>
                  </li>
                  <li>
                    <Link to="/routes" className={nav_link_classes('Routes')}>
                      Routes
                    </Link>
                  </li>
                </>
              )}
            </ul>

            {organisation && (
              <>
                <Link to='/organisations' className='ms-4 btn btn-sm btn-outline-secondary d-flex align-items-center'>
                  <div className='d-flex'>
                    <div className='me-4'>
                      <i className="fas fa-building me-2"></i>
                      {truncateString(organisation.name, 32)}
                    </div>

                    <div className=''>
                      <i className="fas fa-coins me-2"></i>
                      {organisation.left_envelop}km
                    </div>
                  </div>
                  <svg className="ms-2 border-left" xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill="none" strokeWidth="1.5" viewBox="0 0 24 24">
                    <path style={{ fill: 'currentcolor' }} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="m17 8-5-5-5 5M17 16l-5 5-5-5"></path>
                  </svg>
                </Link>
              </>
            )}

            <div className='d-flex align-items-center ms-4'>
              {user.firstname}
              <button onClick={() => handleLogout()} key="logoutPopup" className='ms-4 btn btn-sm btn-outline-secondary'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="none" strokeWidth="1.5" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M12 12h7m0 0-3 3m3-3-3-3M19 6V5a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </header>
      <div className={containerClasses}>
        {organisation && !organisation.active && !pathIsPlayOrCreate && (
          <small className='alert bg-warning p-1'>
            {/* This organisation's order payment hasn't been received yet. They can only use Memomove with limited access. */}
            {capitalizeFirstLetter(t('organisation inactive'))}
          </small>
        )}

        {loadingOrganisation ? (
          <Loader />
        ) : (
          <Routes>
            <Route index path="/" element={
              <Dashboard
                setActiveNav={setPageClasses}
                organisation={organisation}
                t={t}
                // setOrganisation={setOrganisation}
              />
            }
            />
            <Route path="organisations" element={
              <Organisations
                setOrganisation={setOrganisation}
                setIsLoadingOrganisation={setIsLoadingOrganisation}
              />
            }
            />
            {organisation && (
              <>
                <Route path="accounts" element={
                  <Accounts
                    setActiveNav={setPageClasses}
                    orgUuid={organisation.uuid}
                    authType={AUTH_TYPE}
                    organisation={organisation}
                    t={t}
                  />
                }
                />
                <Route path="accounts/closed" element={
                  <ClosedAccounts
                    setActiveNav={setPageClasses}
                    orgUuid={organisation.uuid}
                    organisation={organisation}
                    t={t}
                  />
                }
                />
                <Route path="accounts/new" element={
                  <NewAccount
                    navPage
                    setActiveNav={setPageClasses}
                    organisation={organisation}
                  />
                }
                />
                <Route path="accounts/:uuid/edit" id="uuid" element={
                  <EditAccount
                    setActiveNav={setPageClasses}
                    organisation={organisation}
                  />
                }
                />
                <Route
                  path='routes/new'
                  element={
                    <NewCycleRoute
                      setPageClasses={setPageClasses}
                      authType={AUTH_TYPE}
                      organisation={organisation}
                      getOrganisation={getOrganisation}
                    />
                  }
                />
                <Route
                  path='routes/free/new'
                  element={
                    <NewCycleRoute
                      setPageClasses={setPageClasses}
                      authType={AUTH_TYPE}
                      organisation={organisation}
                      free={true}
                    />
                  }
                />
                <Route
                  path='routes/:id/play'
                  element={
                    <RoutePlayer
                      setActiveNav={setPageClasses}
                      authType={AUTH_TYPE}
                      orgUuid={organisation.uuid}
                      t={t}
                    />
                  }
                />
                <Route
                  path='routes/:id/poi'
                  element={
                    <RoutePoiPlayer
                      setActiveNav={setPageClasses}
                      authType={AUTH_TYPE}
                      orgUuid={organisation.uuid}
                      t={t}
                    />
                  }
                />
                <Route
                  path='routes/:id/create'
                  element={
                    <Creator
                      setActiveNav={setPageClasses}
                      setContainerClasses={setContainerClasses}
                      authType={AUTH_TYPE}
                      organisation={organisation}
                      getOrganisation={getOrganisation}
                    />
                  }
                />
                <Route
                  path='routes/:id/edit'
                  element={
                    <EditCycleRoute
                      setPageClasses={setPageClasses}
                      setContainerClasses={setContainerClasses}
                      authType={AUTH_TYPE}
                      organisation={organisation}
                      getOrganisation={getOrganisation}
                    />
                  }
                />
                
                <Route
                  path='routes/import'
                  element={
                    <ImportRoute
                      organisation={organisation}
                    />
                  }
                />

                <Route path="routes/*" element={
                    <CycleRoutes
                      setPageClasses={setPageClasses}
                      organisation={organisation}
                      authType={AUTH_TYPE}
                      t={t}
                      // location={location}
                    />
                  }
                />
              </>
            )}
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}
      </div>
    </>
  )
}