import { useParams, useNavigate } from "react-router-dom";
// import CycleView from "./CycleView";
import StreetView from "./StreetView";
import { useEffect, useCallback } from "react";
import { errorToast } from "../../Helpers";
import { useTranslation } from 'react-i18next';
import WakeLockStore from "../../../stores/WakeLockStore";

export default function Creator({ setActiveNav, authType, organisation }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('translation');

  useEffect(() => {
    setActiveNav('Routes', 'RoutePlayer')
    WakeLockStore.requestWakeLock()
    document.addEventListener('visibilitychange', WakeLockStore.checkWakeLock)
  }, [setActiveNav])

  const checkLicense = useCallback((community) => {
    if (authType === 'Manager' && community === 'Fr') return

    if (!organisation.active) {
      navigate('/routes')
      if (authType === 'Manager') {
        errorToast(
          t('organisation inactive license')
        )
      } else {
        errorToast(
          t('current organisation inactive license')
        )
      }
    }
  }, [organisation, navigate, t, authType])

  return (
    <StreetView
      authType={authType}
      organisation={organisation}
      checkLicense={checkLicense}
      id={id}
    />
  )
};