import { Link } from 'react-router-dom'
import { truncateString } from '../Helpers'
import { useTranslation } from 'react-i18next';

export default function Header({ headerClasses, nav_link_classes, organisation, user, handleLogout }) {
  const { t } = useTranslation('translation');

  return (
    <header className={headerClasses}>
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-decoration-none text-black">
            <img src='/static/img/logo_website.png' alt='Logo Memomove' />
            <h4 className='ms-2 mb-0'>
              MemoMove | 
              Admin { t("portal") }
            </h4>
          </a>

          <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 ms-4 justify-content-center mb-md-0 align-items-center">
            <li>
              <Link to="/" className={nav_link_classes('Home')}>Home</Link>
            </li>
            <li>
              <Link to="/accounts" className={nav_link_classes('Accounts')}>Accounts</Link>
              
            </li>
            <li>
              <Link to="/routes" className={nav_link_classes('Routes')}>Routes</Link>
            </li>

            <li>
              <a href={process.env.REACT_APP_GO_URL} className='d-flex align-items-center ms-4 btn btn-outline-secondary'>
                <svg className='me-2' width="24px" height="24px" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.90588 4.53682C6.50592 4.2998 6 4.58808 6 5.05299V18.947C6 19.4119 6.50592 19.7002 6.90588 19.4632L18.629 12.5162C19.0211 12.2838 19.0211 11.7162 18.629 11.4838L6.90588 4.53682Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
                Go.MemoMove
              </a>
            </li>
          </ul>

          <div className='rounded-1 px-2 border border-outline-secondary d-flex align-items-center'>
            <div className='me-4'>
              <i className="fas fa-building me-2"></i>
              {truncateString(organisation.name, 24)}
            </div>

            <div className=''>
              <i className="fas fa-coins me-2"></i>
              {organisation.left_envelop}km
            </div>
          </div>

          <div className='d-flex align-items-center ms-4'>
            {user.firstname}

            <button onClick={() => handleLogout()} key="logoutPopup" className='ms-4 btn btn-sm btn-outline-secondary'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="none" strokeWidth="1.5" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M12 12h7m0 0-3 3m3-3-3-3M19 6V5a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </header>
  )
}